import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./context/ProtectedRoute";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import DriverProfile from "./pages/DriverProfile";
import ComingSoonPage from "./pages/ComingSoonPage";
import BecomeSponsorPage from "./pages/BecomeSponsorPage";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import RegisterSuccessPage from "./pages/RegisterPageSuccess";
import DashboardPage from "./pages/driver/DashboardPage";
import EditProfilePage from "./pages/driver/EditProfilePage";
import AccountPage from "./pages/driver/AccountPage";
import SubScribePage from "./pages/driver/SubscribePage";
import SunscriptionSuccessPage from "./pages/driver/SubscriptionSuccessPage";
import MyMenuPage from "./pages/driver/MyMenuPage";
import MyVehiclesPage from "./pages/driver/MyVehiclesPage";
import EditVehiclePage from "./pages/EditVehiclePage";
import EditPaymentLinksPage from "./pages/driver/EditPaymentLinksPage";
import MessagesPage from "./pages/MessagesPage";
import DiscordIdPage from "./pages/DiscordIdPage";
import AdminPage from "./pages/shine/AdminPage";
import PreRegisterPage from "./pages/PreRegisterPage";
import TestPage from "./pages/TestPage";
import FindDriverPage from "./pages/FindDriverPage";
function App() {
  const comingsoon = false;
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {comingsoon ? (
            <Route path="/" element={<PreRegisterPage />} />
          ) : (
            <>
            <Route path="/" element={<HomePage />} />
            <Route path="/find-driver" element={<FindDriverPage />} />
            <Route element={<Layout />}>
              
              <Route path="/test" element={<TestPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/register-success" element={<RegisterSuccessPage />} />
              <Route path="/driver/:username" element={<DriverProfile />} />
              {/* ProtectedRoutes */}
              <Route element={<ProtectedRoute />}>
              <Route path="/subscription-success" element={<SunscriptionSuccessPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/edit-profile" element={<EditProfilePage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/subscribe" element={<SubScribePage />} />
                <Route path="/my-menu" element={<MyMenuPage />} />
                <Route path="/my-vehicles" element={<MyVehiclesPage />} />
                <Route path="/edit-vehicle" element={<EditVehiclePage />} />
                <Route path="/edit-payment-links" element={<EditPaymentLinksPage />} />
                <Route path="/messages" element={<MessagesPage />} />
                <Route path="/shine" element={<AdminPage />} />
                <Route path="/discord-id" element={<DiscordIdPage />} />
              </Route>
            </Route>
            </>
          )}
        </Routes>
      </Router>
    </AuthProvider>
  );
}
export default App;
