import { useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import SpinnerComponent from '../../components/SpinnerComponent';
const AdminPage = () => {
  const [loading, setLoading] = useState(true);
  const [goOn, setGoOn] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [formData, setFormData] = useState({
    comingSoonEnabled: false,
    registerEnabled: false,
    serviceMode: false,
  });
  const getSettings = async () => {
    const functions = getFunctions();
    const getSettings = httpsCallable(functions, 'getSettingsSitewide');
    try {
      const result = await getSettings();
      if(result.data.success === true) {
        setFormData(result.data.settings);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const checkAdmin = async () => {
      const auth = getAuth();
      const functions = getFunctions();
      const isAdmin = httpsCallable(functions, 'isAdmin');
      try {
        const result = await isAdmin();
        if (result.data.success === true && result.data.isadmin === true) {
          await getSettings();
          setLoading(false);
        } else {
          setGoOn(true);
          setLoading(false);
        }
      } catch (error) {
        setGoOn(true);
        setLoading(false);
      }
    };
    checkAdmin(); // Call the async function inside useEffect
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value === 'true',
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setResultMessage('');
    setLoading(true);
    const functions = getFunctions();
    const saveSettings = httpsCallable(functions, 'saveSettingsSitewide');
    try {
      const result = await saveSettings(formData);
      if(result.data.success === true) {
        setResultMessage(result.data.message);
        setLoading(false);
      } else {
        setResultMessage(result.data.error);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      setLoading(false);
    }
  };
  if (goOn) {
    return <div>Error</div>;
  }
  if (loading) {
    return <SpinnerComponent />;
  }
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Admin Page</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700 mb-1">Coming Soon Enabled:</label>
          <select
            name="comingSoonEnabled"
            value={formData.comingSoonEnabled}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </select>
        </div>
        <div>
          <label className="block text-gray-700 mb-1">Register Enabled:</label>
          <select
            name="registerEnabled"
            value={formData.registerEnabled}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </select>
        </div>
        <div>
          <label className="block text-gray-700 mb-1">PreRegister Enabled:</label>
          <select
            name="preRegisterEnabled"
            value={formData.preRegisterEnabled}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </select>
        </div>
        <div>
          <label className="block text-gray-700 mb-1">Service Mode:</label>
          <select
            name="serviceMode"
            value={formData.serviceMode}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded"
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </select>
        </div>
        <div className="text-blue-500">{resultMessage}</div>
        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
        >
          Submit
        </button>
      </form>
    </div>
  );
};
export default AdminPage;
