
import { Button } from './Button'
import { Container } from './Container'
import backgroundImage from '../images/background-call-to-action.jpg'
import { Link } from 'react-router-dom'
export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
      className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
      src={backgroundImage}
      alt=""
      width={2347}
      height={1244}
    />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            How It Works
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
          Drivers can effortlessly join and share their contact details, allowing riders to easily browse and connect with them. Riders can search by location or find nearby drivers in seconds. All communication and payments are directly managed between the rider and driver, giving both parties complete control and flexibility throughout the process.
          </p>
          <Button color="white" className="mt-10">
            <Link to="/register">Get Started</Link>
          </Button>
        </div>
      </Container>
    </section>
  )
}
