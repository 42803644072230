import React, { useRef } from 'react';
const EditProfilePicture = ({ imageUrl, onRemove, onChange }) => {
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <div className='w-full max-w-sm bg-white p-4 shadow-md rounded-lg mx-auto'>
      <div className="text-lg font-bold mb-6 flex justify-center border-b">Profile Picture</div>
      {imageUrl ? (
        <>
          <div className="flex items-center space-x-4 justify-center">
            <img src={imageUrl} alt="Profile" className="inline-block h-48 w-48 rounded-full" />
          </div>
          <div className="mt-4 flex justify-around">
            <button onClick={onRemove} className="bg-red-500 text-white px-4 py-2 rounded-md mt-1">Remove</button>
            <button onClick={handleButtonClick} className="bg-blue-500 text-white px-4 py-2 rounded-md mt-1 ml-2">Change</button>
          </div>
        </>
      ) : (
        <div className="mt-4 flex justify-center">
          <button onClick={handleButtonClick} className="bg-green-500 text-white px-4 py-2 rounded-md mt-1">Upload</button>
        </div>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onChange}
      />
    </div>
  );
};
export default EditProfilePicture;
