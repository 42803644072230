import React from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://rideafide.com/subscription-success', // Replace with your success URL
      },
    });
    if (error) {
      console.log('Error confirming payment:', error.message);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe}
        className="bg-blue-500 text-white p-2 rounded mt-4"
      >
        Pay Now
      </button>
    </form>
  );
};
export default PaymentForm;
