import { getFunctions, httpsCallable } from "firebase/functions";
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../../firebase'; 
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import EditProfilePicture from "../../components/editprofile/EditProfilePicture";
import EditUserInfo from "../../components/editprofile/EditUserInfo";
import SpinnerComponent from "../../components/SpinnerComponent";
const EditProfilePage = () => {
  const [formData, setFormData] = useState({ profilePicture: null, profilePictureAction: "none" });
  const [loading, setLoading] = useState(true); 
  const [uploading, setUploading] = useState(false);
  const [resultMessage, setResultMessage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        const userProfileRef = doc(db, "profiles", userId);
        const fetchUserProfile = async () => {
          try {
            const userProfileDoc = await getDoc(userProfileRef);
            if (userProfileDoc.exists()) {
              const userData = userProfileDoc.data();
              setFormData({
                ...userData,
                profilePicture: userData.profilePicture || null, // Fetch the stored URL
              });
              console.log(formData.profilePicture);
            }
          } catch (error) {
            console.error("Error fetching user profile: ", error);
          } finally {
            setLoading(false);
          }
        };
        fetchUserProfile();
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (formData.profilePicture && !formData.profilePicture.startsWith("http")) {
      const file = new Blob(
        [Uint8Array.from(atob(formData.profilePicture), (c) => c.charCodeAt(0))],
        { type: "image/webp" }
      );
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      return () => URL.revokeObjectURL(url); // Clean up the URL object
    } else {
      setPreviewUrl(formData.profilePicture);
    }
  }, [formData.profilePicture]);
  const handleImageChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0]; // Get the first selected file
    if (file) {
      console.log('Image found: ', file);
      setUploading(true);
      // Check if the file is an image
      if (!file.type.startsWith("image/")) {
        setResultMessage("Please upload a valid image file.");
        setUploading(false);
        return;
      }
      try {
        // Convert the image file to a base64 string
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result.split(',')[1]; // Remove the data URL prefix
          setFormData({
            ...formData,
            profilePicture: base64Image, // Store base64 string for backend
            profilePictureAction: "upload",
          });
          console.log('Base64 Image Created:', base64Image);
        };
        // Read the file as a Data URL
        reader.readAsDataURL(file);
      } catch (error) {
        setResultMessage("Error processing the image.");
      } finally {
        setUploading(false);
      }
    }
  };
  const handleRemoveImage = () => {
    setFormData({ ...formData, profilePicture: null, profilePictureAction: "remove" });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResultMessage(null);
    const functions = getFunctions();
    const updateUserProfile = httpsCallable(functions, "updateUserProfile");
    try {
      await updateUserProfile(formData);
      setResultMessage("Profile updated successfully");
    } catch (error) {
      setResultMessage(error.message);
    } finally {
      setLoading(false);
    }
  };
  if(loading){
    return <SpinnerComponent />
  }
  return (
    <>
    <EditProfilePicture
      imageUrl={previewUrl}
      onRemove={handleRemoveImage}
      onChange={handleImageChange}
      onAdd={handleImageChange}
    />
    <form onSubmit={handleSubmit}>
      <EditUserInfo
        handleChange={handleChange}
        formData={formData}
        loading={loading}
        setLoading={setLoading}
        setFormData={setFormData}
        uploading={uploading}
        resultMessage={resultMessage}
      />
    </form>
    </>
  );
};
export default EditProfilePage;
