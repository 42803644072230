import React, { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { useAuth } from '../context/AuthContext';
import OnlineToggle from './OnlineToggle'; // Import the toggle component

// Define links at the top of the file
const links = [
  { name: 'Home', href: '/' },
  //{ name: 'About', href: '/about' },
  //{ name: 'Services', href: '/services' },
  //{ name: 'Contact', href: '/contact' }
];
const driverLinks = [
  { name: 'Dashboard', href: '/dashboard' },
  //{ name: 'Messages', href: '/messages' },
  //{ name: 'Edit Profile', href: '/edit-profile' },
  //{ name: 'My Vehicles', href: '/my-vehicles' },
  //{ name: 'Payment Links', href: '/edit-payment-links' },
  // { name: 'Link Discord', href: '/discord-id' },
  { name: 'Account', href: '/account' }
];

const HeaderComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { authLoading, currentUser, logout } = useAuth();

  if (authLoading) {
    return null;
  }

  return (
    <>
      {/* Main Header */}
      <header className="w-full bg-blue-600 py-4 text-white flex justify-between items-center px-4">
        {/* Logo/Text */}
        <div className="text-lg font-bold">Rideafide</div>
        {/* Online/Offline Toggle */}
        {/* <OnlineToggle /> */}
        {/* Hamburger Button */}
        <button onClick={() => setIsOpen(true)} className="text-white focus:outline-none text-2xl">
          &#9776; {/* Hamburger Icon */}
        </button>
      </header>
      {/* Drawer Menu */}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-40 flex justify-end">
        <DialogPanel className="w-64 bg-white p-4 shadow-lg">
          {/* Close Button */}
          <div className="flex justify-end">
            <button onClick={() => setIsOpen(false)} className="text-black text-2xl mb-4 focus:outline-none">
              &times; {/* Close X Icon */}
            </button>
          </div>
          {/* Drawer Links */}
          <div className='mt-6 border-t pt-4'>
            <ul>
              {links.map((link) => (
                <li key={link.name} className="mb-2">
                  <a href={link.href} className="text-blue-600 hover:text-blue-800">
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* Driver Links */}
          {currentUser && (
            <>
              {driverLinks.map((link) => (
                <div className="mt-6 border-t pt-4">
                <a key={link.name} href={link.href} className="text-blue-600 hover:text-blue-800 w-full block text-left">
                  {link.name}
                </a>
                </div>
              ))}
            </>
          )}
          {/* Login/Logout Link */}
          <div className="mt-6 border-t pt-4">
            {currentUser ? (
              <button onClick={logout} className="text-red-600 hover:text-red-800 w-full text-left">
                Logout
              </button>
            ) : (
              <a href="/login" className="text-blue-600 hover:text-blue-800 w-full block text-left">
                Login
              </a>
            )}
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default HeaderComponent;
