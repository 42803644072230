import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const CountdownComponent = () => {
  const targetDate = new Date("2024-11-17T23:59:59"); // Set your target date and time here
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetDate));
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(targetDate));
    }, 1000);
    return () => clearInterval(timer); // Cleanup the interval on unmount
  }, [targetDate]);
  function getTimeLeft(target) {
    const now = new Date();
    const difference = target - now;
    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return (
    <div className="flex flex-col items-center justify-center p-6 bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
      <h1 className="text-4xl font-bold mb-6">Countdown to Full Launch</h1>
      <div className="grid grid-cols-4 gap-6 text-center">
        <div className="p-4 bg-white text-indigo-600 rounded-lg shadow-lg">
          <p className="text-3xl font-bold">{timeLeft.days}</p>
          <p className="text-xl">Days</p>
        </div>
        <div className="p-4 bg-white text-indigo-600 rounded-lg shadow-lg">
          <p className="text-3xl font-bold">{timeLeft.hours}</p>
          <p className="text-xl">Hours</p>
        </div>
        <div className="p-4 bg-white text-indigo-600 rounded-lg shadow-lg">
          <p className="text-3xl font-bold">{timeLeft.minutes}</p>
          <p className="text-xl">Minutes</p>
        </div>
        <div className="p-4 bg-white text-indigo-600 rounded-lg shadow-lg">
          <p className="text-3xl font-bold">{timeLeft.seconds}</p>
          <p className="text-xl">Seconds</p>
        </div>
      </div>
      <p className="mt-8 text-xl text-center w-1/2">
        We're opening up early access for drivers to sign up before we launch to riders! 
        Drivers who join now will get a <strong>**50% discount on their subscription for as long as it's active**</strong>. 
        Don't miss out on this limited-time offer—sign up today!
      </p>
      <Link
        to="/register"
        className="mt-6 inline-block bg-white text-indigo-600 font-bold py-2 px-6 rounded-full shadow-lg hover:bg-indigo-600 hover:text-white transition-all"
      >
        Sign Up Now
      </Link>
    </div>
  );
};
export default CountdownComponent;
