import React, { useState, useEffect } from 'react';
import SpinnerComponent from './SpinnerComponent';
import { functions } from '../firebase.js';
import { httpsCallable } from 'firebase/functions';
const DashboardProfileForm = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    city: '',
    state: '',
    listingActive: false,
    photoURL: '',
    aboutMe: '',
    phone: '',
    canText: false,
  });
  useEffect(() => {
    const fetchProfile = async () => {
      const getMyProfile = httpsCallable(functions, 'getMyProfile');
      try {
        const result = await getMyProfile();
        if (result.data.success) {
          setFormData(result.data.profile);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);
    try {
      // Remove photoURL from the data being sent to the backend
      const { photoURL, ...profileData } = formData;
      const updateMyProfile = httpsCallable(functions, 'updateMyProfile');
      const result = await updateMyProfile(profileData);
      if (result.data.success) {
        setSuccess(result.data.message);
      } else {
        setError(result.data.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleAddPhotoClick = (e) => {
    document.getElementById('fileInput').click(); // Programmatically trigger file input
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      try {
        // Convert file to base64 for sending to backend
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64File = reader.result.split(',')[1]; // Extract base64 data
          // Call the backend to handle file upload and validation
          const updateMyPhoto = httpsCallable(functions, 'updateMyPhoto');
          const result = await updateMyPhoto({ file: base64File, fileName: file.name });
          if (result.data.success) {
            console.log('Photo updated successfully:', result.data.message);
            setFormData((prevData) => ({
              ...prevData,
              photoURL: result.data.photoURL, // Update photoURL in formData
            }));
          } else {
            console.error('Error updating photo:', result.data.message);
          }
        };
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
        };
      } catch (error) {
        console.error('Error sending file to backend:', error);
      }
    }
  };
  const handleRemovePhoto = async () => {
    setLoading(true); // Start loading before making the API call
    try {
      const removeMyPhoto = httpsCallable(functions, 'removeMyPhoto');
      const result = await removeMyPhoto();
      if (result.data.success) {
        console.log('Photo removed successfully:', result.data.message);
        setFormData((prevData) => ({
          ...prevData,
          photoURL: '', // Clear the photoURL from formData
        }));
      } else {
        console.error('Error removing photo:', result.data.message);
      }
    } catch (error) {
      console.error('Error removing photo:', error);
    } finally {
      setLoading(false); // Stop loading after the API call is completed
    }
  };
  
  if (loading) {
    return <SpinnerComponent />;
  }
  return (
    <div className="max-w-2xl mx-auto p-8 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Profile Information</h2>
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
      <div className="m-auto text-center border rounded-md mb-6">
        {formData.photoURL ? (
          <>
            <p className="font-bold text-2xl p-4">Photo</p>
            <img 
              src={formData.photoURL}
              alt="User photo" 
              className="rounded-full h-[200px] w-[200px] m-auto" 
            />
            <div className="w-full flex justify-around mb-4 mt-4">
              <button className="bg-red-500 text-white px-4 py-2 rounded-md mt-1" onClick={handleRemovePhoto}>Remove</button>
            </div>
          </>
        ) : (
          <div className="w-full flex justify-around mb-4 flex-col">
            <p className="font-bold text-lg p-4">Photo</p>
            {/* Button to trigger file input */}
            <button 
              className="bg-blue-500 text-white px-4 py-2 rounded-md mt-1 ml-2" 
              onClick={handleAddPhotoClick}
            >
              Add Photo
            </button>
            {/* Hidden file input */}
            <input 
              type="file" 
              id="fileInput" 
              style={{ display: 'none' }} 
              accept="image/*"
              onChange={handleFileChange} 
            />
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            className="border border-gray-300 p-2 rounded"
            required
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            className="border border-gray-300 p-2 rounded"
            required
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
            className="border border-gray-300 p-2 rounded"
            required
          />
          <select
  name="state"
  value={formData.state}
  onChange={handleChange}
  className="border border-gray-300 p-2 rounded"
  required
>
  <option value="">Select a state</option>
  <option value="AL">Alabama</option>
  <option value="AK">Alaska</option>
  <option value="AZ">Arizona</option>
  <option value="AR">Arkansas</option>
  <option value="CA">California</option>
  <option value="CO">Colorado</option>
  <option value="CT">Connecticut</option>
  <option value="DE">Delaware</option>
  <option value="FL">Florida</option>
  <option value="GA">Georgia</option>
  <option value="HI">Hawaii</option>
  <option value="ID">Idaho</option>
  <option value="IL">Illinois</option>
  <option value="IN">Indiana</option>
  <option value="IA">Iowa</option>
  <option value="KS">Kansas</option>
  <option value="KY">Kentucky</option>
  <option value="LA">Louisiana</option>
  <option value="ME">Maine</option>
  <option value="MD">Maryland</option>
  <option value="MA">Massachusetts</option>
  <option value="MI">Michigan</option>
  <option value="MN">Minnesota</option>
  <option value="MS">Mississippi</option>
  <option value="MO">Missouri</option>
  <option value="MT">Montana</option>
  <option value="NE">Nebraska</option>
  <option value="NV">Nevada</option>
  <option value="NH">New Hampshire</option>
  <option value="NJ">New Jersey</option>
  <option value="NM">New Mexico</option>
  <option value="NY">New York</option>
  <option value="NC">North Carolina</option>
  <option value="ND">North Dakota</option>
  <option value="OH">Ohio</option>
  <option value="OK">Oklahoma</option>
  <option value="OR">Oregon</option>
  <option value="PA">Pennsylvania</option>
  <option value="RI">Rhode Island</option>
  <option value="SC">South Carolina</option>
  <option value="SD">South Dakota</option>
  <option value="TN">Tennessee</option>
  <option value="TX">Texas</option>
  <option value="UT">Utah</option>
  <option value="VT">Vermont</option>
  <option value="VA">Virginia</option>
  <option value="WA">Washington</option>
  <option value="WV">West Virginia</option>
  <option value="WI">Wisconsin</option>
  <option value="WY">Wyoming</option>
</select>
        </div>
        <textarea
          name="aboutMe"
          value={formData.aboutMe}
          onChange={handleChange}
          placeholder="About Me"
          className="border border-gray-300 p-2 w-full rounded"
          rows="4"
          required
        />
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone"
          className="border border-gray-300 p-2 w-full rounded"
          required
        />
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            name="canText"
            checked={formData.canText}
            onChange={handleChange}
            className="w-5 h-5"
          />
          <label className="text-gray-700">Can Text?</label>
        </div>
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            name="listingActive"
            checked={formData.listingActive}
            onChange={handleChange}
            className="w-5 h-5"
          />
          <label className="text-gray-700">Listing Active</label>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white p-3 rounded hover:bg-blue-700 transition"
        >
          Save
        </button>
      </form>
    </div>
  );
};
export default DashboardProfileForm;
