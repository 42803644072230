import {Link} from 'react-router-dom'
import { Popover, PopoverButton, PopoverOverlay, PopoverPanel, Transition, TransitionChild, } from '@headlessui/react'
import clsx from 'clsx'
import { Button } from './Button'
import { Container } from './Container'
import { useAuth } from '../context/AuthContext'
import SpinnerComponent from '../components/SpinnerComponent';
function MobileNavLink({ href, children }) {
  return (
    <PopoverButton as={Link} href={href} className="block w-full p-2">
      {children}
    </PopoverButton>
  )
}
function MobileNavIcon({ open }) {
  return (
    <svg aria-hidden="true" className="h-3.5 w-3.5 overflow-visible stroke-slate-700" fill="none" strokeWidth={2} strokeLinecap="round" >
      <path d="M0 1H14M0 7H14M0 13H14" className={clsx( 'origin-center transition', open && 'scale-90 opacity-0', )} />
      <path d="M2 2L12 12M12 2L2 12" className={clsx( 'origin-center transition', !open && 'scale-90 opacity-0', )} />
    </svg>
  )
}
function MobileNavigation() {
  return (
    <Popover>
      <PopoverButton className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none" aria-label="Toggle Navigation" >
        {({ open }) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <Transition>
        <TransitionChild enter="duration-150 ease-out" enterFrom="opacity-0" enterTo="opacity-100" leave="duration-150 ease-in" leaveFrom="opacity-100" leaveTo="opacity-0" >
          <PopoverOverlay className="fixed inset-0 bg-slate-300/50" />
        </TransitionChild>
        <TransitionChild enter="duration-150 ease-out" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="duration-100 ease-in" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" >
          <PopoverPanel className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5">
            <MobileNavLink href="#features">Features</MobileNavLink>
            <MobileNavLink href="#testimonials">Testimonials</MobileNavLink>
            <MobileNavLink href="#pricing">Pricing</MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink href="/login">Sign in</MobileNavLink>
          </PopoverPanel>
        </TransitionChild>
      </Transition>
    </Popover>
  )
}
export function Header() {
  const { currentUser, authLoading, logout } = useAuth(); // Get the current user from AuthContext
  // Show a loading spinner while the authentication status is loading
  if (authLoading) {
    return (
      <SpinnerComponent />
    ); 
  }
  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              Rideafide
            </Link>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {currentUser ? (
              <div className="flex items-center gap-x-6">
                <Link to="/dashboard" className="text-sm text-blue-600 hover:underline">
                  Driver Dashboard
                </Link>
                <button
                  onClick={logout}
                  className="text-sm text-blue-600 hover:underline"
                >
                  Logout
                </button>
              </div>
            ) : (
              <>
                <div className="hidden md:block">
                  <Link to="/login">Sign in</Link>
                </div>
                <Button color="blue">
                  <span>
                    <Link to="/register">Get started <span className="hidden lg:inline">today</span></Link>
                  </span>
                </Button>
                <div className="-mr-1 md:hidden">
                  <MobileNavigation />
                </div>
              </>
            )}
          </div>
        </nav>
      </Container>
    </header>
  );
}
