import React, { useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
const DiscordIdPage = () => {
  const [discordId, setDiscordId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isLinked, setIsLinked] = useState(false); // New state to track if Discord ID is linked
  // Function to extract query parameters from URL
  const getQueryParam = (param) => {
    return new URLSearchParams(window.location.search).get(param);
  };
  useEffect(() => {
    setLoading(true);
    // Check if user already has a linked Discord ID on page load
    checkIfUserHasDiscordId();
    // Check for code in the URL
    const code = getQueryParam('code');
    const state = getQueryParam('state');
    if (code) {
      // If code is present, exchange it for the Discord ID
      exchangeCodeForDiscordId(code, state);
    }
    setLoading(false);
  }, []);
  // Function to check if user already has a Discord ID linked
  const checkIfUserHasDiscordId = async () => {
    setLoading(true);
    const functions = getFunctions();
    const getUserDiscordId = httpsCallable(functions, 'getUserDiscordId');
    try {
      const result = await getUserDiscordId();
      if (result.data.success && result.data.discordId) {
        setDiscordId(result.data.discordId);
        setIsLinked(true); // Update the state to show that Discord ID is linked
      }
    } catch (err) {
      console.error('Failed to check if user has Discord ID:', err);
    }
    setLoading(false);
  };
  // Function to call the backend and exchange code for Discord ID
  const exchangeCodeForDiscordId = async (code, state) => {
    setLoading(true);
    setError('');
    const functions = getFunctions();
    const exchangeCode = httpsCallable(functions, 'exchangeCodeForDiscordId');
    try {
      // Call Firebase function to exchange code
      const result = await exchangeCode({ code, state });
      setDiscordId(result.data.discordId);
    } catch (err) {
      setError(err.message || 'Failed to exchange code for Discord ID');
    }
    setLoading(false);
  };
  const handleAddDiscordId = async () => {
    setLoading(true);
    setError('');
    const functions = getFunctions();
    const addDiscordId = httpsCallable(functions, 'addDiscordId');
    try {
      // Call Firebase function to initiate OAuth2 flow
      const result = await addDiscordId();
      // Redirect user to the Discord OAuth2 URL
      window.location.href = result.data.authUrl;
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4 text-center">Add Your Discord ID</h1>
        {isLinked && (
          <div className="mb-4 p-4 bg-green-100 text-green-700 border border-green-300 rounded">
            <p>Your Discord ID is currently linked:</p>
            <p className="font-mono">{discordId}</p>
          </div>
        )}
        {discordId && !isLinked ? (
          <div className="text-center text-green-500">
            <p>Your Discord ID has been added successfully:</p>
            <p className="font-mono mt-2">{discordId}</p>
          </div>
        ) : (
          <>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full"
              onClick={handleAddDiscordId}
              disabled={loading}
            >
              {loading ? 'Connecting...' : 'Connect with Discord'}
            </button>
            {error && (
              <div className="mt-4 text-center text-red-500">
                <p>Error: {error}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default DiscordIdPage;
