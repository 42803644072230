import { Link } from "react-router-dom";

const SunscriptionSuccessPage = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-1">
        <div className="m-auto text-center">
          <h1 className="text-4xl font-bold mb-4">Subscription Successful!</h1>
          <p className="text-lg text-gray-700">Your subscription has been activated.</p>
        </div>
        <div className="m-auto text-center">
          <Link to="/dashboard">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Go to Dashboard
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};  

export default SunscriptionSuccessPage;