import React, { useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const InboxComponent = ({ selectConversation }) => {
  const [conversations, setConversations] = useState([]);
  const firestore = getFirestore();
  const auth = getAuth();
  
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
    
    const userId = user.uid;
    const conversationsRef = collection(firestore, 'conversations');
    const q = query(conversationsRef, where('participants', 'array-contains', userId), orderBy('updatedAt', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedConversations = snapshot.docs.map(doc => {
        const data = doc.data();
        const otherParticipantId = data.participants.find((id) => id !== userId); // Find the recipient ID
        return {
          id: doc.id,
          otherParticipantId, // Add recipient ID here
          ...data
        };
      });

      setConversations(fetchedConversations);
    }, (error) => {
      console.error('Error fetching conversations:', error);
    });

    return () => unsubscribe();
  }, [firestore, auth]);

  return (
    <div className="p-4 h-full">
      <div className="text-lg font-semibold mb-4">Inbox</div>
      <div className="space-y-4 overflow-y-auto">
        {conversations.length > 0 ? (
          conversations.map((conversation) => (
            <div
              key={conversation.id}
              className="p-4 border border-gray-300 rounded-md hover:bg-gray-100 cursor-pointer"
              onClick={() => selectConversation(conversation.id, conversation.otherParticipantId)}
            >
              <div className="font-medium">{conversation.participantName}</div>
              <div className="text-sm text-gray-600">{conversation.latestMessage}</div>
              <div className="text-xs text-gray-400">{new Date(conversation.updatedAt).toLocaleString()}</div>
            </div>
          ))
        ) : (
          <div>No conversations available</div>
        )}
      </div>
    </div>
  );
};

export default InboxComponent;
