import { useState } from "react";
import { getAuth, updatePassword, sendEmailVerification } from "firebase/auth";
import { Link } from "react-router-dom";
import SubStatus from "../../components/subscription/SubStatus";
const AccountPage = () => {
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const auth = getAuth();
  const user = auth.currentUser;
  const handleSendEmailVerification = () => {
    if (user && newEmail) {
      sendEmailVerification(user)
        .then(() => setMessage("Verification email sent. Please check your inbox."))
        .catch((error) => setMessage(`Error sending verification email: ${error.message}`));
    }
  };
  const handleUpdatePassword = () => {
    if (user && newPassword) {
      updatePassword(user, newPassword)
        .then(() => setMessage("Password updated successfully"))
        .catch((error) => setMessage(`Error updating password: ${error.message}`));
    }
  };
  return (
    <div className="mainBox flex justify-center items-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <div className="text-xl font-semibold mb-6 border-b pb-4 text-gray-700">
          My Account
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">New Email</label>
          <input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter new email"
          />
          <button
            onClick={handleSendEmailVerification}
            className="w-full mt-4 bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition"
          >
            Send Verification Email
          </button>
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">New Password</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter new password"
          />
          <button
            onClick={handleUpdatePassword}
            className="w-full mt-4 bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition"
          >
            Update Password
          </button>
        </div>
        {message && <div className="text-center text-red-500 mt-4">{message}</div>}
        <div className="mt-6">
          <a
            href="https://billing.stripe.com/p/login/00g3edb71aFueE87ss"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full inline-block text-center bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 transition"
          >
            Go to Billing Portal
          </a>
        </div>
      </div>
    </div>
  );
};
export default AccountPage;
