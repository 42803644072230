import { Hero } from '../components/Hero';
import { CallToAction } from '../components/CallToAction';
import { SecondaryFeatures } from '../components/SecondaryFeatures';
import { Pricing } from '../components/Pricing';
import { Faqs } from '../components/Faqs';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import CountdownComponent from '../components/CountdownComponent';
export default function HomePage() {
  return (
    <>
    <Header />
   <main>
    <CountdownComponent />
    <Hero />
    <CallToAction />
    <SecondaryFeatures />
    <Pricing />
    <Faqs />
   </main>
    <Footer />
    </>
  );
}