import { useId } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';
import { Container } from './Container';
const features = [
  {
    name: 'Driver Profiles',
    summary: 'Create a personalized profile to showcase your driving services.',
    description:
      'Provide details about yourself, your vehicle, rates, and preferred locations. Attract riders by standing out with a unique profile.',
    icon: ({ id }) => (
      <>
        <defs>
          <linearGradient id={id} x1="11.5" y1={18} x2={36} y2="15.5" gradientUnits="userSpaceOnUse">
            <stop offset=".194" stopColor="#fff" />
            <stop offset={1} stopColor="#34D399" />
          </linearGradient>
        </defs>
        <path d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5" stroke={`url(#${id})`} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      </>
    ),
  },
  {
    name: 'Browse Listings',
    summary: 'Riders can easily browse and find drivers that match their needs.',
    description:
      'Our platform allows riders to search and filter through various driver profiles based on location, rates, and vehicle type.',
    icon: ({ id }) => (
      <>
        <path opacity=".5" d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff" />
        <path opacity=".3" d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff" />
        <path d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff" />
      </>
    ),
  },
  {
    name: 'Grow Your Rider Base',
    summary: 'Build a loyal rider base and get more rides.',
    description:
      'Expand your reach by connecting directly with riders, setting your own rates, and growing your network organically through great service.',
    icon: ({ id }) => (
      <>
        <path opacity=".5" d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75c.357.421.993.419 1.384.028c.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z" fill="#fff" />
        <path d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5s1.343 3.5 3 3.5s3-1.567 3-3.5Z" fill="#fff" />
      </>
    ),
  },
];
function Feature({ feature, isActive, className, ...props }) {
  const id = useId();
  return (
    <div className={clsx(className, !isActive && 'opacity-75 hover:opacity-100')} {...props}>
      <div className={clsx('w-9 rounded-lg', isActive ? 'bg-green-600' : 'bg-slate-500')}>
        <svg aria-hidden="true" className="h-9 w-9" fill="none">
          <feature.icon id={id} />
        </svg>
      </div>
      <h3 className={clsx('mt-6 text-sm font-medium', isActive ? 'text-green-600' : 'text-slate-600')}>
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">{feature.summary}</p>
      <p className="mt-4 text-sm text-slate-600">{feature.description}</p>
    </div>
  );
}
function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.name}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
        </div>
      ))}
    </div>
  );
}
function FeaturesDesktop() {
  return (
    <TabGroup className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <TabList className="grid grid-cols-3 gap-x-8">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.name}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="ui-not-focus-visible:outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </TabList>
          <TabPanels className="relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16">
            <div className="-mx-5 flex">
              {features.map((feature, featureIndex) => (
                <TabPanel
                  static
                  key={feature.name}
                  className={clsx(
                    'px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none',
                    featureIndex !== selectedIndex && 'opacity-60',
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                  {/* Feature content goes here */}
                </TabPanel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
          </TabPanels>
        </>
      )}
    </TabGroup>
  );
}
export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for enhancing the driver and rider experience"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Take Charge Of Your Driving
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Maximize your earnings, build your rider base, and drive on your terms.
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  );
}
