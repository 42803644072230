import { initializeApp, getApps } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
// import { getMessaging, getToken, onMessage } from 'firebase/messaging'; // Remove messaging import

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBczUqsaoTY0gHUo2VUMdrKhSU3M3ntqa4",
  authDomain: "driverpanion.firebaseapp.com",
  projectId: "driverpanion",
  storageBucket: "driverpanion",
  messagingSenderId: "230519529076",
  appId: "1:230519529076:web:324a2a5119af4c7a607b21",
};

// Initialize Firebase app only if it hasn't been initialized yet
const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
// const messaging = getMessaging(app); // Remove messaging initialization

// Check if using emulator
if (process.env.REACT_APP_USE_EMULATOR === "true") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}



export { auth, db, storage, functions, httpsCallable, signInAnonymously };
